.align-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logo {
  width: 100%;
}

.info-box {
  width: 360px;
  height: 450px;
  box-shadow: 21px 7px 63px #0000000d;
  border-radius: 15px;
  background: white;
  position: relative;
  top: 60px;
  padding: 0 0em 0.5em 0em;
  margin: 1em;

  @media (max-width: 1000px) {
    top: 0px;
  }

  @media (max-width: 700px) {
    width: 400px;
    height: 360px;
    padding-bottom: 0;
    padding-left: 1em;
    padding-right: 1em;
    margin-bottom: 0;
  }


  img {
    margin-top: 2em;
    margin-bottom: 1em;
    width: 320px;
    height: 240px;

    @media (max-width: 700px) {
      width: 200px;
      height: 160px;
    }
  }


  &-title {
    text-align: center;
    font-family: "sansita-light";
    font-size: 1.5em;
    line-height: 32px;
  
  }

  &-desc {
    margin-top: 0.5em;
    margin-bottom: 1em;
    padding: 0em 1em;
    font-family: "overpass-light";
    font-size: 1em;
    line-height: 1.5em;
    text-align: center;


    @media (max-width: 700px) {
      margin-bottom: 0em;
      padding: 0em;
    }
  }


}

.homepage {
  &-logo {
    padding-top: 2em;
    padding-right: 2em;
    width: 180px;
    float: right;  

    @media (max-width: 800px) {
      padding: 1em;
    }
  }

  .info-boxes { 
    height: 300px;
    background: #f4dac9;
    display: flex;
    justify-content: center;

  }

  .hotels {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 1em;
    margin-bottom: 0px; 
    @media (max-width: 1300px) {
      margin-bottom: 0px;
    }
  }

  &-dog {
    height: 360px;
    background: #f4dac9;
    width: 100%;
    position: relative;

    @media (max-width: 1300px) {
      height: fit-content;
      padding: 2em 0px;
    }


    &-text {
      color: white;
      position: absolute;
      bottom: 0;
      left: 0;
      padding-left: 3em;
      padding-bottom: 2.5em;

      @media (max-width: 750px) {
        padding-left: 1.5em;
        padding-bottom: 1.25em;
      }
    }
  }
}
.no-space {
  padding: 0px;
  margin: 0px;
}
.mb-xs {
  margin-bottom: 0.25em;
}
.mb-sm {
  margin-bottom: 1em;

  @media (max-width: 800px) {
    margin-bottom: 1em;
  }
}
.mb-md {
  margin-bottom: 2em;
}
.mb-lg {
  margin-bottom: 4em;
}
.mr-xs {
  margin-right: 0.25em;
}
.mr-sm {
  margin-right: 1em;
}
.mr-md {
  margin-right: 2em;
}
.space-letters {
  letter-spacing: 0.5px;
}
.space-letters-sm {
  letter-spacing: 1px;
  font-weight: 100;
}
.sansita {
  font-family: 'sansita-light';
}
.hotel-text {
  margin-left: 0.5em;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ml-auto {
  margin-left: auto;
}

.book-now-image {
  width: 270px !important;
  @media (max-width: 700px) {
    width: 190px !important;
  }
}
