.is-sticky {
  position: fixed;
  top: 65px;
  left: 0;
  width: 100%;
  z-index: 100000;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
  display: block;
  padding-bottom: 0;
}
