.header-wrapper {
  .filter-bar-wrapper {
    width: 100%;
    height: 800px;
    @media screen and (max-width: 1600px) {
      height: 660px;
    }
    @media screen and (max-width: 600px) {
      height: 660px;
    }
 

    &-title {
      font-family: "sansita-light";
      font-size: 3em;
      letter-spacing: 0.5px;
      line-height: 1em;
      color: white;
      margin-bottom: 0.25em;

      @media screen and (max-width: 800px) {
        font-size: 2.25em;
      }
    }

    &-desc {
      font-family: "sansita-light";
      font-size: 1.5em;
      line-height: 24px;
      color: white;
      letter-spacing: 1.75px;

      @media screen and (max-width: 800px) {
        font-size: 1.25em;
      }
    }
  }
  .filter-bar-desktop {    
    user-select: none;
    background: white;
    border-radius: 15px;
    border: 4px solid #D3D3D3;//#009ca1;
    box-sizing: border-box;
    z-index: 11;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .auto-complete-input {
      input {
        padding: 0px;
        font-size: 1em;
        font-family: overpass-light;
        cursor: pointer;
        border: none;
        color: black;

        @media screen and (max-width: 600px) {
          font-size: 1em;
        }

        &::placeholder {
          text-overflow: ellipsis !important;
          opacity: 1;
        }
      }
    }


    .number-inputs {
      display: flex;
      align-items: center;

      @media screen and (max-width: 1120px) {
        margin-bottom: 1em;
      }

      @media screen and (max-width: 600px) {
        width: 100%;
        align-items: center;
      }

      .number-input {
        margin-left: 30px;

        @media screen and (max-width: 600px) {
          margin-left: 0px;
        }
      }
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}




