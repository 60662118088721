@font-face {
    font-family: 'ModernEra-Regular';
    src: url(./assets/font/ModernEra-Regular.woff) format('woff'),
         url(./assets/font/ModernEra-Regular.woff2) format('woff2');
    font-style: normal;
    font-weight: normal;
    text-rendering: optimizeLegibility;
    font-display: swap;
}

@font-face {
    font-family: 'ModernEra-Regular';
    src: url(./assets/font/ModernEra-Bold.woff) format('woff'),
         url(./assets/font/ModernEra-Bold.woff2) format('woff2');
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    font-display: swap;
}

@font-face {
    font-family: 'ModernEra-Bold';
    src: url(./assets/font/ModernEra-Bold.woff) format('woff'),
         url(./assets/font/ModernEra-Bold.woff2) format('woff2');
    font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
    font-display: swap;
}

@font-face {
  font-family: "overpass-light";
  src: url(./assets/font/ModernEra-Regular.woff) format('woff'),
         url(./assets/font/ModernEra-Regular.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: "overpass-regular";
  src: url(./assets/font/mangueira-regular.woff) format('woff');
  font-display: swap;
}

@font-face {
  font-family: "overpass-bold";
  src: url(./assets/font/mangueira-regular.woff) format('woff');
}

@font-face {
  font-family: "sansita-light";
  src: url(./assets/font/ModernEra-Regular.woff) format('woff'),
    url(./assets/font/ModernEra-Regular.woff2) format('woff2');
  font-display: swap;
}

@font-face {
  font-family: "sansita-bold";
  src: url(./assets/font/calibre-regular.woff2) format('woff2');
}

.shadow-rounded {
  -webkit-box-shadow: 2px 3px 6px 0px rgba(214,214,214,1);
  -moz-box-shadow: 2px 3px 6px 0px rgba(214,214,214,1);
  box-shadow: 2px 3px 6px 0px rgba(214,214,214,1);
  border-radius: 100%;
  width: 44px;
  height: 44px;
}

.Cal__Container__root {
  font-family: "overpass-light", sans-serif !important;
}

body,
html {
  font-family: "overpass-light", sans-serif;
}

h1, h2, h3 {
  padding: 0;
  margin: 0;
  font-weight: 500;
}

.overpass {
  font-family: "overpass-light" !important;
}

.MuiAutocomplete-groupLabel {
  font-family: 'sansita-light';
  padding: 0;
}

.MuiAutocomplete-groupUl {
  border-top: 1px solid gray;
  //padding: 0 1em;
  //font-family: 'sansita-light' !important;
}

.MuiAutocomplete-groupUl li {
  //color: #009CA1 !important;
}

a:-webkit-any-link {
text-decoration: none;
}

/* stripe */
.css-2kkxbv {
  padding: 0.75rem 0.75rem 0.75rem 0.5rem !important;
}


